import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'semantic-ui-react';
import base from '../../../../config/firebase';
import { toast } from 'react-toastify';
import { ToastContent } from '../../../Toast/Toast';
import UserDropdown from '../../AssignmentDropdown/UserDropdown';
import { formatRefsToObject } from '../../../../helpers/format';

export default class ProjectForm extends Component {
  static propTypes = {
    companyID: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
  }

  state = {
    project: {
      name: "",
      userRefs: []
    }
  }

  validForm = () => {
    const { project } = this.state
    let valid = true 

    if ( project.name === "" ) valid = false

    return valid
  }

  handleChange = (e, data) => {
    e.preventDefault()
    let { project } = this.state
    project[data.name] = data.value

    this.setState({ project })
  }

  submitForm = (e) => {
    e.preventDefault()
    const { project } = this.state
    const { companyID, close } = this.props
    const companyProjectsRef = `/companies/${companyID}/projects`
    project.userRefs = formatRefsToObject(project.userRefs)
    
    // Push project to current company
    base.push(companyProjectsRef, { data: project })
      .then(async (newLocation) => {
        const projectID = newLocation.key
        const projectRef = companyProjectsRef.concat(`/${projectID}`)
        await Promise.all(Object.keys(project.userRefs).map(async (id) => {
          const userProjectRef = `/users/${id}/projectRefs`

          // Add project ref to assigned user
          return await base.update(userProjectRef, { data: { [projectID]: projectRef }})
            .catch(err => { throw err })
        }))

        
        toast.success(<ToastContent success message="Project created" />)
        close()
      })
      .catch(err => {
        console.error(err)
        toast.error(<ToastContent error />)
      })
  }

  render() {
    const { companyID } = this.props

    return (
      <Form onSubmit={this.submitForm}>
        <Form.Input
          required
          label="Project Name"
          name="name"
          onChange={this.handleChange}
        />
        <Form.Field required>
          <label>User Assignment</label>
          <UserDropdown
            multiple
            companyID={companyID}
            handleChange={this.handleChange}
          />
        </Form.Field>
        <Form.Button
          primary
          content="Create"
          disabled={!this.validForm()}
          type="submit"
        />
      </Form>
    )
  }
}
