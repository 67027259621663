import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader, Header } from 'semantic-ui-react';

import base from '../../../config/firebase';
import { FAKE_LOAD_TIMER } from '../../../helpers/constants';
import { formatTaskCountDetail, formatUserCountDetail, attachRefToObjects } from '../../../helpers/format';
import NoDataCard from '../NoDataCard/NoDataCard';
import TaskTypePanel from '../DataPanel/TaskTypePanel';

export default class TaskTypes extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  }

  state = {
    companyID: undefined,
    taskType: undefined,
    taskTypesData: {},
    loading: true
  }

  componentDidMount = () => {
    const { companyID } = this.props.match.params
    
    this.setTaskTypeListener(companyID)
  }

  componentWillUnmount = async () => {
    if ( this.ref ) await base.removeBinding(this.ref)
  }

  setTaskTypeListener = async (companyID) => {
    if ( !companyID ) return 
    const taskTypesRef = `/companies/${companyID}/taskTypes`

    if ( this.ref ) await base.removeBinding(this.ref)
    this.ref = base.listenTo(taskTypesRef, {
      context: this,
      then(taskTypesData) {
        taskTypesData = attachRefToObjects(taskTypesData, taskTypesRef)
        let { taskType } = this.state
        if ( taskType ) taskType = taskTypesData[taskType.id]
        
        this.setTaskTypes(taskTypesData, taskType)
      }
    })
  }

  setTaskTypes = async (taskTypesData = {}, taskType) => {
    this.setState({ taskTypesData, taskType })
    setTimeout(() => {
      this.setState({ loading: false })
    }, FAKE_LOAD_TIMER)
  }

  handleBoxClick = (e, taskTypeID = "") => {
    e.preventDefault()

    this.showTaskTypePanel(taskTypeID)
  }

  showTaskTypePanel = (taskTypeID = "") => {
    this.setState({
      taskType: this.state.taskTypesData[taskTypeID]
    })
  }

  closeTaskTypePanel = () => {
    this.setState({ taskType: undefined })
  }

  render() {
    const { taskType, taskTypesData, loading } = this.state
    const dataKeys = Object.keys(taskTypesData || {})

    return (
      <div className="taskTypes section dimmable">
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <div className="grid">
          { dataKeys.length === 0 ? (
            <NoDataCard type="taskTypes" />
          ) : dataKeys.map(key => (
            <TaskTypeBox key={key} taskType={taskTypesData[key]} onClick={this.handleBoxClick} />
          ))}
        </div>
        { taskType && (
          <TaskTypePanel
            taskTypeRef={taskType.ref}
            close={this.closeTaskTypePanel}
          />
        )}
      </div>
    )
  }
}

class TaskTypeBox extends Component {
  static propTypes = {
    taskType: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
  }

  render() {
    const { taskType, onClick } = this.props
    const userCount = Object.keys(taskType.userRefs || {}).length
    const taskCount = Object.keys(taskType.taskRefs || {}).length

    return (
      <div className="taskType box" onClick={(e) => onClick(e, taskType.id)} >
        <Header as="h3" content={taskType.name} />
        <div className="details">
          <Header as="h5" content={formatTaskCountDetail(taskCount)} />
          <Header as="h5" content={formatUserCountDetail(userCount)} />
        </div>
      </div>
    )
  }
}
