import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify';
import { clone } from 'lodash';

import './DataPanel.css'
import base from '../../../config/firebase';
import { ToastContent } from '../../Toast/Toast';
import DataPanel from './DataPanel';
import { getLastIDFromUrl } from '../../../helpers/format';

export default class EquipmentPanel extends Component {
  static propTypes = {
    equipmentRef: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
  }

  state = {
    equipmentRef: "",
    equipment: {},
    editName: "",
    userData: {},
    availableUsers: {}
  }

  componentDidMount = () => {
    const { equipmentRef } = this.props

    this.setEquipmentListener(equipmentRef)
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if ( nextProps.equipmentRef ) {
      if ( !prevState.equipment || nextProps.equipmentRef !== prevState.equipmentRef ) {
        return { equipmentRef : nextProps.equipmentRef };
      }
    }

    return null
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { equipmentRef } = this.state

    if ( !prevState.equipment || prevState.equipmentRef !== equipmentRef ) {
      this.setEquipmentListener(equipmentRef)
    }
  }

  setEquipmentListener = async (ref = "") => {
    if ( this.ref ) await base.removeBinding(this.ref)
    this.ref = base.listenTo(ref, {
      context: this,
      then(equipment) {
        this.setState({ editName: equipment.name, equipment })
        this.setAvailableUsers(equipment.userRefs)
        this.setUserData(equipment.userRefs)
      }
    })
  }

  setAvailableUsers = async (userRefs = {}) => {
    let availableUsers = await base.fetch('/users', { context: this })
    Object.keys(userRefs).forEach(id => {
      delete availableUsers[id]
    })

    this.setState({ availableUsers })
  }

  setUserData = async (userRefs = {}) => {
    let userData = {}
    await Promise.all(Object.keys(userRefs).map(async (key) => {
      if ( typeof userRefs[key] !== "string" ) return 
      userData[key] = await base.fetch(userRefs[key], { context: this })
    }))

    this.setState({ userData })
  }

  handleNameChange = (e, data) => {
    e.preventDefault()
    this.setState({ editName: data.value })
  }

  resetEditName = (e) => {
    e.preventDefault()
    this.setState({ editName: this.state.equipment.name })
  }

  saveName = (e) => {
    e.preventDefault()
    const { editName } = this.state
    const { equipmentRef } = this.props

    base.update(equipmentRef, { data: { name: editName }})
      .catch(() => {
        toast.error(<ToastContent error message="Failed to update equipment" />)
      })
  }

  addUser = async (e, data) => {
    e.preventDefault()
    let { equipment } = this.state
    let userID = data.value
    let userRefs = clone(equipment.userRefs || {})
    if ( userRefs && userRefs[userID] ) return 

    userRefs[userID] = `/users/${userID}`
    await base.update(this.props.equipmentRef, { data: { userRefs }})
      .then(async () => {
        const equipmentID = getLastIDFromUrl(this.props.equipmentRef)
        await base.update(`/users/${userID}/equipmentRefs`, { data: { [equipmentID]: this.props.equipmentRef }})
      })
      .catch(err => {
        console.error(err)
        toast.error(<ToastContent error message="Failed to add user" />)
      })
  }

  removeUser = async (e, userID) => {
    e.preventDefault()
    let { equipment } = this.state
    let userRefs = clone(equipment.userRefs)
    if ( !userRefs || !userRefs[userID] ) return 

    delete userRefs[userID]
    await base.update(this.props.equipmentRef, { data: { userRefs }})
      .then(async () => {
        const equipmentID = getLastIDFromUrl(this.props.equipmentRef)
        await base.remove(`/users/${userID}/equipmentRefs/${equipmentID}`)
      })
      .catch(err => {
        console.error(err)
        toast.error(<ToastContent error message="Failed to remove user" />)
      })
  }

  deleteEquipment = async (e) => {
    e.preventDefault()
    const { equipmentRef, close } = this.props
    const equipmentID = getLastIDFromUrl(equipmentRef)
    let { equipment } = this.state

    // Remove equipment ref from users
    await Promise.all(Object.values(equipment.userRefs || {}).map(async (userRef) => {
      return await base.remove(`${userRef}/equipmentRefs/${equipmentID}`)
    })).catch(err => {
      console.error(err)
      toast.error(<ToastContent error message="Failed to remove equipment" />)
    })

    // Remove equipment ref from tasks
    await Promise.all(Object.values(equipment.taskRefs || {}).map(async (taskRef) => {
      return await base.remove(`${taskRef}/equipmentRef/${equipmentID}`)
    })).catch(err => {
      console.error(err)
      toast.error(<ToastContent error message="Failed to remove equipment" />)
    })

    await base.remove(equipmentRef).catch(err => {
      console.error(err)
      toast.error(<ToastContent error message="Failed to remove equipment" />)
    })

    toast.success(<ToastContent success message="Equipment deleted" />)
    close()
  }

  render() {
    const { close } = this.props
    const { equipment, editName, userData, availableUsers } = this.state

    return (
      <DataPanel
        type={"equipment"}
        data={equipment}
        editDataName={editName}
        tableData={userData}
        availableData={availableUsers}
        close={close}
        saveDataName={this.saveName}
        handleDataNameChange={this.handleNameChange}
        resetEditDataName={this.resetEditName}
        addData={this.addUser}
        removeData={this.removeUser}
        deleteData={this.deleteEquipment}
      />
    )
  }
}
