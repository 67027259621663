import React from 'react'

import './SvgIcon.css'

export default function SvgIcon ({
  name,
  color = 'white',
  onClick = () => { return },
  className = "",
  disabled = false
}) {

  return (
    <div
      className={`svg-icon ${name} ${color} ${className}`}
      onClick={() => { if (!disabled) { onClick() }}}
    />
  )
}