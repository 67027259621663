import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Header } from 'semantic-ui-react';

import './NewDataModal.css';
import { serviceToNewDataHeader } from '../../../helpers/banks';
import SvgIcon from '../../SvgIcon/SvgIcon';
import Forms from './Forms/Forms';

export default class NewDataModal extends Component {
  static propTypes = {
    companyID: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
  }

  render() {
    const { close, service, companyID } = this.props

    return (
      <Modal
        open
        className="new-data-modal"
        onClose={close}
        closeOnDimmerClick={false}
        size="small"
      >
        <div className='header'>
          <Header content={`New ${serviceToNewDataHeader[service]}`} />
          <SvgIcon name='close' color='white' onClick={close} />
        </div>
        <Modal.Content>
          <Forms companyID={companyID} service={service} close={close} />
        </Modal.Content>
      </Modal>
    )
  }
}
