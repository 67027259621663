import base, { fire } from "../config/firebase";
import firebase from 'firebase'

// Start a sign in process for an unauthenticated user.
const provider = new firebase.auth.GoogleAuthProvider();
provider.addScope('profile');
provider.addScope('email');
export function signInWithGoogle(){
  return fire.auth().signInWithRedirect(provider);
}

// WARNING: Use admin cloud functions instead to prevent auto sign-in
export function logout() {
  return fire.auth().signOut()
}

export function resetPassword (email) {
  return fire.auth().sendPasswordResetEmail(email)
}

export async function firebaseFetch(url = "") {
  return await fire.database().ref(url).once("value").then(returnSnapshotValue)
}

export function returnSnapshotValue(snapshot = {}) {
  return snapshot.val()
}

export function getActiveUserID() {
  return fire.auth().currentUser.uid
}

export async function getActiveUserProfile() {
  return await base.fetch(`/users/${getActiveUserID()}`, { context: this })
}