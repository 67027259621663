import React, { Component } from 'react'
import PropTypes from 'prop-types'
import base from '../../../config/firebase';
import { Header, Dimmer, Loader } from 'semantic-ui-react';
import { formatUserCountDetail, formatTaskCountDetail, attachRefToObjects } from '../../../helpers/format';
import { FAKE_LOAD_TIMER } from '../../../helpers/constants';
import NoDataCard from '../NoDataCard/NoDataCard';
import ProjectPanel from '../DataPanel/ProjectPanel';

export default class Projects extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  }

  state = {
    companyID: undefined,
    projectsData: {},
    project: undefined,
    loading: true
  }

  componentDidMount = () => {
    const { companyID } = this.props.match.params
    
    this.setProjectListener(companyID)
  }

  componentWillUnmount = async () => {
    if ( this.ref ) await base.removeBinding(this.ref)
  }

  setProjectListener = async (companyID) => {
    if ( !companyID ) return 
    const projectsRef = `/companies/${companyID}/projects`

    if ( this.ref ) await base.removeBinding(this.ref)
    this.ref = base.listenTo(projectsRef, {
      context: this,
      then(projectsData) {
        projectsData = attachRefToObjects(projectsData, projectsRef)
        let { project } = this.state
        if ( project ) project = projectsData[project.id]
        
        this.setProjects(projectsData, project)
      }
    })
  }

  setProjects = async (projectsData = {}, project) => {
    this.setState({ projectsData, project })
    setTimeout(() => {
      this.setState({ loading: false })
    }, FAKE_LOAD_TIMER)
  }

  handleBoxClick = (e, projectID = "") => {
    e.preventDefault()

    this.showProjectPanel(projectID)
  }

  showProjectPanel = (projectID = "") => {
    this.setState({
      project: this.state.projectsData[projectID]
    })
  }

  closeProjectPanel = () => {
    this.setState({ project: undefined })
  }

  render() {
    const { projectsData, project, loading } = this.state
    const dataKeys = Object.keys(projectsData || {})
    
    return (
      <div className="projects section dimmable">
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <div className="grid">
          { dataKeys.length === 0 ? (
            <NoDataCard type="projects" />
          ) : dataKeys.map(key => (
            <ProjectBox key={key} project={projectsData[key]} onClick={this.handleBoxClick} />
          ))}
        </div>
        { project && (
          <ProjectPanel
            projectRef={project.ref}
            close={this.closeProjectPanel}
          />
        )}
      </div>
    )
  }
}

class ProjectBox extends Component {
  static propTypes = {
    project: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
  }

  render() {
    const { project, onClick } = this.props
    const userCount = Object.keys(project.userRefs || {}).length
    const taskCount = Object.keys(project.taskRefs || {}).length

    return (
      <div className="project box" onClick={(e) => onClick(e, project.id)}>
        <Header as="h3" content={project.name} />
        <div className="details">
          <Header as="h5" content={formatTaskCountDetail(taskCount)} />
          <Header as="h5" content={formatUserCountDetail(userCount)} />
        </div>
      </div>
    )
  }
}