export const serviceNameBank = {
  tasks: "Tasks",
  projects: "Projects",
  equipment: "Equipment",
  taskTypes: "Task Types",
  management: "Management"
}

export const serviceToNewDataHeader = {
  tasks: "Task",
  projects: "Project",
  equipment: "Equipment",
  taskTypes: "Task Type",
  management: "User"
}

export const serviceWithCreate = {
  "Tasks": true,
  "Projects": true,
  "Equipment": true,
  "Task Types": true,
  "Management": true
}