import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Header, Form, Button, Table } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { clone } from 'underscore';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';


import './DataPanel.css';
import base from '../../../config/firebase';
import { ToastContent } from '../../Toast/Toast';
import SvgIcon from '../../SvgIcon/SvgIcon';
import { getLastIDFromUrl, getTaskDetails, formatTime, formatElapsedTime } from '../../../helpers/format';
import TaskTypeDropdown from '../AssignmentDropdown/TaskTypeDropdown';
import ProjectDropdown from '../AssignmentDropdown/ProjectDropdown';
import EquipmentDropdown from '../AssignmentDropdown/EquipmentDropdown';
Moment.locale('en');
momentLocalizer();


export default class TaskPanel extends Component {
  static propTypes = {
    taskRef: PropTypes.string.isRequired,
    companyID: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired
  }

  state = {
    taskRef: "",
    task: {},
    editTask: {},
    userData: {},
    availableUsers: {}
  }

  componentDidMount = () => {
    const { taskRef } = this.props

    this.setTaskListener(taskRef)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if ( nextProps.taskRef ) {
      if ( !prevState.task || nextProps.taskRef !== prevState.taskRef ) {
        return { taskRef : nextProps.taskRef };
      }
    }

    return null
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { taskRef } = this.state

    if ( !prevState.task || prevState.taskRef !== taskRef ) {
      this.setTaskListener(taskRef)
    }
  }

  setTaskListener = async (ref = "") => {
    if ( this.ref ) await base.removeBinding(this.ref)
    this.ref = base.listenTo(ref, {
      context: this,
      then(task) {
        this.setTask(task)
      }
    })
  }

  setTask = async (task) => {
    task = await getTaskDetails(task)
    
    this.setState({ task }, () => {
      if ( !this.state.editMode ) this.resetEditTask()
    })
  }

  toggleEditMode = () => {
    this.setState({ editMode: !this.state.editMode }, () => {
      if ( !this.state.editMode ) this.resetEditTask()
    })
  }

  handleChange = (e, data) => {
    e.preventDefault()
    let { editTask } = this.state
    editTask[data.name] = data.value
    
    this.setState({ editTask, editMode: true })
  }

  changeTime = (value, name) => {
    let { editTask } = this.state
    editTask[name] = value
    if ( name === 'start' && editTask.start.valueOf() > editTask.stop.valueOf() ) editTask.stop = editTask.start
    if ( name === 'stop' && editTask.stop.valueOf() < editTask.start.valueOf() ) editTask.start = editTask.stop
    
    this.setState({ editTask, editMode: true })
  }

  resetEditTask = (e) => {
    this.setState({ editTask: clone(this.state.task) })
  }

  cancelEditMode = () => {
    this.toggleEditMode()
  }

  validForm = () => {
    const { editTask } = this.state
    let valid = true
    console.log(editTask)
    if ( !editTask.start || !editTask.stop ) valid = false
    else if ( !editTask.taskTypeRef ) valid = false

    return valid
  }

  saveTask = async (e) => {
    e.preventDefault()
    const { editTask } = this.state
    const { taskRef } = this.props
    const task = clone(this.state.task)
    const taskID = getLastIDFromUrl(taskRef)
    const data = {
      start: new Date(editTask.start).valueOf(),
      stop: new Date(editTask.stop).valueOf(),
      taskTypeRef: editTask.taskTypeRef,
      projectRef: editTask.projectRef,
      equipmentRef: editTask.equipmentRef
    }
    
    await base.update(taskRef, { data })
      .then(async () => {
        // Update refs
        console.log(task)
        console.log(data)
        if ( task.taskTypeRef !== data.taskTypeRef ) {
          if ( task.taskTypeRef ) await base.remove(`${task.taskTypeRef}/taskRefs/${taskID}`)
          if ( data.taskTypeRef ) await base.update(`${data.taskTypeRef}/taskRefs`, { data: { [taskID]: data.taskTypeRef }})
        }
        if ( task.projectRef !== data.projectRef ) {
          if ( task.projectRef ) await base.remove(`${task.projectRef}/taskRefs/${taskID}`)
          if ( data.projectRef ) await base.update(`${data.projectRef}/taskRefs`, { data: { [taskID]: data.projectRef }})
        }
        if ( task.equipmentRef !== data.equipmentRef ) {
          if ( task.equipmentRef ) await base.remove(`${task.equipmentRef}/taskRefs/${taskID}`)
          if ( data.equipmentRef ) await base.update(`${data.equipmentRef}/taskRefs`, { data: { [taskID]: data.equipmentRef }})
        }
      })
      .catch(() => {
        return toast.error(<ToastContent error message="Failed to update task" />)
      })

    toast.success(<ToastContent success message="Task updated" />)
    this.toggleEditMode()
  }

  deleteTask = async (e) => {
    e.preventDefault()
    const { task } = this.state
    const { taskRef, close } = this.props
    const taskID = getLastIDFromUrl(taskRef)

    // Remove task ref from taskType
    if ( task.taskTypeRef ) await base.remove(`${task.taskTypeRef}/taskRefs/${taskID}`).catch(err => {
      console.error(err)
      return toast.error(<ToastContent error message="Failed to remove task" />)
    })

    // Remove task ref from equipment
    if ( task.equipmentRef ) await base.remove(`${task.equipmentRef}/taskRefs/${taskID}`).catch(err => {
      console.error(err)
      return toast.error(<ToastContent error message="Failed to remove task" />)
    })

    // Remove task ref from project
    if ( task.projectRef ) await base.remove(`${task.projectRef}/taskRefs/${taskID}`).catch(err => {
      console.error(err)
      return toast.error(<ToastContent error message="Failed to remove task" />)
    })

    // Remove task ref from user
    if ( task.userRef ) await base.remove(`${task.userRef}/taskRefs/${taskID}`).catch(err => {
      console.error(err)
      return toast.error(<ToastContent error message="Failed to remove task" />)
    })

    await base.remove(taskRef).catch(err => {
      console.error(err)
      return toast.error(<ToastContent error message="Failed to remove task" />)
    })

    toast.success(<ToastContent success message="Task deleted" />)
    close()
  }

  render() {
    const { task, editTask, editMode } = this.state
    const { close, companyID, isAdmin } = this.props

    return (
      <div className='data-panel task'>
        <div className='header row spc-btwn'>
          <Header content={task.name || "Task Details"} />
          <SvgIcon name='close' color='gray' onClick={close} />
        </div>
        { !editMode && (
          <div className="task-details">
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell content="Start Time" />
                  <Table.Cell content={formatTime(task.start)} />
                </Table.Row>
                <Table.Row>
                  <Table.Cell content="Stop Time" />
                  <Table.Cell content={formatTime(task.stop)} />
                </Table.Row>
                <Table.Row>
                  <Table.Cell content="Elapsed Time" />
                  <Table.Cell content={formatElapsedTime(task.stop - task.start)} />
                </Table.Row>
                { task.taskTypeName && (
                  <Table.Row>
                    <Table.Cell content="Task Type Name" />
                    <Table.Cell content={task.taskTypeName} />
                  </Table.Row>
                )}
                { task.projectName && (
                  <Table.Row>
                    <Table.Cell content="Project Name" />
                    <Table.Cell content={task.projectName} />
                  </Table.Row>
                )}
                { task.equipmentName && (
                  <Table.Row>
                    <Table.Cell content="Equipment Name" />
                    <Table.Cell content={task.equipmentName} />
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
        )}
        { editMode && (
          <Form>
            <Form.Field required>
              <label>Start Time</label>
              <DateTimePicker
                onChange={(value) => this.changeTime(value, "start")}
                step={5}
                value={new Date(editTask.start)}
                format={"hh:mm A"}
                date={false}
              />
            </Form.Field>
            <Form.Field required>
              <label>Stop Time</label>
              <DateTimePicker
                onChange={(value) => this.changeTime(value, "stop")}
                step={5}
                value={new Date(editTask.stop)}
                format={"hh:mm A"}
                date={false}
              />
            </Form.Field>
            <Form.Field required>
              <label>Task Type</label>
              <TaskTypeDropdown
                name="taskTypeRef"
                companyID={companyID}
                handleChange={this.handleChange}
                defaultValue={task.taskTypeRef}
              />
            </Form.Field>
            <Form.Field>
              <label>Project</label>
              <ProjectDropdown
                name="projectRef"
                companyID={companyID}
                handleChange={this.handleChange}
                defaultValue={task.projectRef}
              />
            </Form.Field>
            <Form.Field>
              <label>Equipment</label>
              <EquipmentDropdown
                name="equipmentRef"
                companyID={companyID}
                handleChange={this.handleChange}
                defaultValue={task.equipmentRef}
              />
            </Form.Field>
            <div className="row">
              <Form.Button
                content="Cancel"
                onClick={this.cancelEditMode}
              />
              <Form.Button
                primary
                content="Save"
                onClick={this.saveTask}
                disabled={!this.validForm()}
              />
            </div>
          </Form>
        )}
        { !editMode && isAdmin && (
          <Fragment>
            <Button
              primary
              content="Edit"
              onClick={this.toggleEditMode}
            />
            <Button
              negative
              className="delete-btn"
              content="Delete"
              onClick={this.deleteTask}
            />
          </Fragment>
        )}
      </div>
    )
  }
}
