import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './SideBar.css';
import SvgIcon from '../../SvgIcon/SvgIcon';
import { serviceNameBank } from '../../../helpers/banks';
import { isAdmin } from '../../../helpers/access';

export default class SideBar extends Component {
  static propTypes = {
    generateServicePath: PropTypes.func.isRequired,
    accessCode: PropTypes.number.isRequired
  }

  render() {
    const { generateServicePath, accessCode } = this.props
    const services = !isAdmin(accessCode) ? [{ name: "tasks" }] : [
      { name: "tasks" },
      { name: "projects" },
      { name: "equipment" },
      { name: "taskTypes" },
      { name: "management" },
    ]

    return (
      <div className="sidebar">
        <div className="logo">
          <span />
        </div>
        <div className="services">
          { services.map(service => (
            <Link to={generateServicePath(service.name)} key={service.name} className={`service ${service.active ? 'active' : ''}`} >
              <SvgIcon name={service.name} color='white' />
              <span className='link'>{serviceNameBank[service.name]}</span>
            </Link>
          ))}
        </div>
        <span className='copyright'>&copy; {new Date().getFullYear()} Autonomous Labs</span>
      </div>
    )
  }
}
