import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Header, Button, Form } from 'semantic-ui-react';
import SvgIcon from '../../SvgIcon/SvgIcon';
import base from '../../../config/firebase';
import { getProjectOptions, getEquipmentOptions, getTaskTypeOptions } from '../../../helpers/options';
import { getLastIDFromUrl } from '../../../helpers/format';
import { toast } from 'react-toastify';
import { ToastContent } from '../../Toast/Toast';
import { getActiveUserID } from '../../../helpers/firebase';

export default class UserPanel extends Component {
  static propTypes = {
    companyID: PropTypes.string.isRequired,
    userRef: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
  }

  state = {
    userRef: "",
    user: {},
    editUser: undefined,
    editMode: false,
    projectOptions: [],
    equipmentOptions: [],
    taskTypeOptions: []
  }

  componentDidMount = () => {
    const { userRef, companyID } = this.props

    this.setOptions(companyID)
    this.setUserListener(userRef)
  }
  
  setOptions = async (companyID) => {
    const projectOptions = await getProjectOptions(companyID, true)
    const equipmentOptions = await getEquipmentOptions(companyID, true)
    const taskTypeOptions = await getTaskTypeOptions(companyID, true)

    this.setState({ projectOptions, equipmentOptions, taskTypeOptions })
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if ( nextProps.userRef ) {
      if ( !prevState.user || nextProps.userRef !== prevState.userRef ) {
        return { userRef : nextProps.userRef };
      }
    }

    return null
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { userRef } = this.state

    if ( !prevState.user || prevState.userRef !== userRef ) {
      this.setState({ editUser: undefined }, () => {
        this.setUserListener(userRef)
      })
    }
  }

  setUserListener = async (ref = "") => {
    if ( this.ref ) await base.removeBinding(this.ref)
    this.ref = base.listenTo(ref, {
      context: this,
      then(user) {
        if ( !this.state.editUser ) this.prepareEditUser(user)
        this.setState({ user })
      }
    })
  }

  prepareEditUser = (user) => {
    let editUser = {
      name: user.name,
      projectRefs: Object.values(user.projectRefs || {}),
      equipmentRefs: Object.values(user.equipmentRefs || {}),
      taskTypeRefs: Object.values(user.taskTypeRefs || {}),
    }

    this.setState({ editUser, editMode: false })
  }

  handleChange = (e, data) => {
    e.preventDefault()
    let { editUser } = this.state
    editUser[data.name] = data.value

    this.setState({ editUser, editMode: true })
  }

  resetEditUser = () => {
    this.prepareEditUser(this.state.user)
  }

  saveUser = () => {
    const { user, editUser } = this.state
    const newUserData = this.prepareUser(editUser)

    base.update(`/users/${user.uid}`, { data: newUserData })
      .then(() => {
        toast.success(<ToastContent success message="User updated" />)
        this.resetEditUser()
      })
      .catch(err => {
        console.error(err)
        toast.error(<ToastContent error />)
      })
  }

  prepareUser = (data) => {
    let user = {
      name: data.name,
      projectRefs: {},
      equipmentRefs: {},
      taskTypeRefs: {}
    }

    data.projectRefs.forEach(projectRef => {
      user.projectRefs[getLastIDFromUrl(projectRef)] = projectRef
    })

    data.equipmentRefs.forEach(equipmentRef => {
      user.equipmentRefs[getLastIDFromUrl(equipmentRef)] = equipmentRef
    })

    data.taskTypeRefs.forEach(taskTypeRef => {
      user.taskTypeRefs[getLastIDFromUrl(taskTypeRef)] = taskTypeRef
    })

    return user
  }

  deleteUser = async () => {
    const { user } = this.state
    const { close } = this.props
    const userID = user.uid
    const userRef = `/users/${userID}`

    // Remove user ref from projects
    await Promise.all(Object.values(user.projectRefs || {}).map(async (projectRef) => {
      return await base.remove(`${projectRef}/userRefs/${userID}`)
    })).catch(err => {
      console.error(err)
      return toast.error(<ToastContent error message="Failed to remove user" />)
    })

    // Remove user ref from equipment
    await Promise.all(Object.values(user.equipmentRefs || {}).map(async (equipmentRef) => {
      return await base.remove(`${equipmentRef}/userRefs/${userID}`)
    })).catch(err => {
      console.error(err)
      return toast.error(<ToastContent error message="Failed to remove user" />)
    })

    // Remove user ref from taskTypes
    await Promise.all(Object.values(user.taskTypeRefs || {}).map(async (taskTypeRef) => {
      return await base.remove(`${taskTypeRef}/userRefs/${userID}`)
    })).catch(err => {
      console.error(err)
      return toast.error(<ToastContent error message="Failed to remove user" />)
    })

    await base.remove(userRef).catch(err => {
      console.error(err)
      return toast.error(<ToastContent error message="Failed to remove user" />)
    })

    toast.success(<ToastContent success message="User deleted" />)
    close()
  }

  render() {
    const { user, editUser, editMode, projectOptions, equipmentOptions, taskTypeOptions } = this.state
    const { close } = this.props

    return (
      <div className='data-panel user'>
        <div className='header row spc-btwn'>
          <Header content={user.name || "User Details"} />
          <SvgIcon name='close' color='gray' onClick={close} />
        </div>
        { editUser && (
          <Form onSubmit={this.saveUser}>
            <Form.Input
              label={<Header as="h4" content="Edit Name" />}
              name="name"
              value={editUser.name}
              onChange={this.handleChange}
            />
            <Form.Dropdown
              multiple search selection
              label={<Header as="h4" content="Project Assignment" />}
              placeholder="Select..."
              value={editUser.projectRefs}
              options={projectOptions}
              name="projectRefs"
              onChange={this.handleChange}
            />
            <Form.Dropdown
              multiple search selection
              label={<Header as="h4" content="Equipment Assignment" />}
              placeholder="Select..."
              value={editUser.equipmentRefs}
              options={equipmentOptions}
              name="equipmentRefs"
              onChange={this.handleChange}
            />
            <Form.Dropdown
              multiple search selection
              label={<Header as="h4" content="Task Type Assignment" />}
              placeholder="Select..."
              value={editUser.taskTypeRefs}
              options={taskTypeOptions}
              name="taskTypeRefs"
              onChange={this.handleChange}
            />
            { editMode && (
              <div className="row spc-btwn">
                <Form.Button
                  content="Cancel"
                  onClick={this.resetEditUser}
                />
                <Form.Button
                  primary
                  content="Save"
                  type="submit"
                />
              </div>
            )}
          </Form>
        )}
        { user.uid !== getActiveUserID() && (
          <Button
            negative
            className="delete-btn"
            content="Delete"
            onClick={this.deleteUser}
          />
        )}
      </div>
    )
  }
}
