import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Header } from 'semantic-ui-react';

import './NoDataCard.css'

export default class NoDataCard extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired
  }

  render() {
    const { type } = this.props

    return (
      <div className={`${type} no-data box`}>
        <Header as="h3" content={`No ${type} found`} />
      </div>
    )
  }
}
