import { serviceNameBank } from "./banks";
import { clone } from 'lodash'
import moment from "moment";
import base from "../config/firebase";

export const capitalize = (word = "") => {
  if ( typeof word !== "string" ) word = ""
  return word.charAt(0).toUpperCase() + word.substr(1);
}

export function friendlyEmailRef(email) {
  email = email.replace(/\./g, "2AY");
  email = email.replace(/@/g, "2AZ");

  return email
}

export function prettifyService(service) {
  return serviceNameBank[service] || capitalize(service)
}

export const nonPluralWords = {
  "equipment": true
}

export function plural(word = "", count = 0) {
  if ( nonPluralWords[word] ) return word
  count = parseInt(count)
  if ( count > 1 || count === 0 ) return word + "s"

  return word
}

export function formatUserCountDetail(userCount) {
  return `${userCount} ${plural("user", userCount)} assigned`
}

export function formatTaskCountDetail(taskCount) {
  return `${taskCount} ${plural("task", taskCount)} generated`
}

export function formatUserAssignmentDetail(type, count) {
  return `${count} ${plural(type, count)} assigned`
}

export function formatTaskName(task) {
  return task.id
}

export function getLastIDFromUrl(url = "") {
  let split = url.split("/")
  return split[split.length - 1]
}

export function formatRefsToObject(refs = []) {
  let objectRefs = {}
  refs.forEach(ref => {
    objectRefs[getLastIDFromUrl(ref)] = ref
  })

  return objectRefs
}

export function attachRefToObjects(objects, baseRef) {
  let objectsWithRef = {}
  Object.keys(objects).forEach(key => {
    objectsWithRef[key] = clone(objects[key])
    objectsWithRef[key].id = key
    objectsWithRef[key].ref = `${baseRef}/${key}`
  })

  return objectsWithRef
}

export function getCompanyIDFromPath(path = "") {
  path = path.split('/')
  path.splice(0, 1)
  
  return path[1]
}

export function getServiceFromPath(path = "") {
  path = path.split('/')
  path.splice(0, 1)

  return path[0]
}

export function formatElapsedTime(elapsedTime) {
  elapsedTime = moment(elapsedTime)
  let timeString = ""
  if ( elapsedTime.hour() - 19 !== 0 ) timeString = timeString.concat(elapsedTime.hour() - 19 +"h ")
  if ( elapsedTime.minutes() !== 0 ) timeString = timeString.concat(elapsedTime.minutes() +"m ")
  if ( elapsedTime.seconds() !== 0 ) timeString = timeString.concat(elapsedTime.seconds() +"s")

  return timeString
}

export async function getTaskDetails(task = {}) {
  if ( task.equipmentRef ) task.equipmentName = await getDataName(task.equipmentRef)
  if ( task.projectRef ) task.projectName = await getDataName(task.projectRef)
  if ( task.userRef ) task.userName = await getDataName(task.userRef)
  if ( task.taskTypeRef ) {
    task.taskTypeName = await getDataName(task.taskTypeRef)
    task.name = task.taskTypeName
  } else task.name = "Task"

  return task
}

async function getDataName(ref) {
  let name = await base.fetch(`${ref}/name`, { context: this })
  
  if ( typeof name !== 'string' ) return undefined
  return name
}

export function formatTime(timestamp) {
  return moment(timestamp).format("MMMM D, YYYY") + " at " + moment(timestamp).format("hh:mma")
}