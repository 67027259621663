export const ACCESS_CODES = {
  MASTER: 0,
  ADMIN: 10,
  USER: 20
}

export const accessRoleCodeToText = {
  0: "Master",
  10: "Admin",
  20: "User",
}

export function isMaster(code = ACCESS_CODES.USER) {
  return code === ACCESS_CODES.MASTER
}

export function isAdmin(code = ACCESS_CODES.USER) {
  return isMaster(code) || code <= ACCESS_CODES.ADMIN
}

export function isUser(code = ACCESS_CODES.USER) {
  return code >= ACCESS_CODES.USER
}

export function getAccessOptions(userCode = ACCESS_CODES.ADMIN) {
  let accessOptions = []

  Object.values(ACCESS_CODES).forEach(code => {
    if ( userCode <= code) accessOptions.push({ key: code, value: code, text: accessRoleCodeToText[code]})
  })

  return accessOptions
}