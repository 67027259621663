import React, { Component } from 'react'
import PropTypes from 'prop-types'

import base from '../../../config/firebase';
import AssignmentDropdown from './AssignmentDropdown';

export default class UserDropdown extends Component {
  static propTypes = {
    companyID: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool
  }

  state = {
    userOptions: []
  }

  componentDidMount = () => {
    const { companyID, multiple } = this.props

    this.setUserOptions(companyID, multiple)
  }

  setUserOptions = async ( companyID, multiple = false ) => {
    const users = await base.fetch(`/users/`, { context: this })
    const userOptions = multiple ? [] : [{ key: 'none', value: undefined, text: "None" }]

    Object.keys(users).forEach((id) => {
      let user = users[id]
      if ( user.companies[companyID] ) userOptions.push({
        key: id,
        value: `/users/${id}`,
        text: user.name
      })
    })

    this.setState({ userOptions })
  }

  render() {
    const { userOptions } = this.state
    const { handleChange, disabled, multiple } = this.props

    return (
      <AssignmentDropdown
        name="userRefs"
        type="users"
        options={userOptions}
        handleChange={handleChange}
        disabled={disabled}
        multiple={multiple}
      />
    )
  }
}
