import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Header, Form, Button } from 'semantic-ui-react';

import './DataPanel.css'
import SvgIcon from '../../SvgIcon/SvgIcon';
import AssignmentTable from './AssignmentTable/AssignmentTable';

export default class DataPanel extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    editDataName: PropTypes.string.isRequired,
    tableData: PropTypes.object.isRequired,
    availableData: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    saveDataName: PropTypes.func.isRequired,
    handleDataNameChange: PropTypes.func.isRequired,
    resetEditDataName: PropTypes.func.isRequired,
    addData: PropTypes.func.isRequired,
    removeData: PropTypes.func.isRequired,
    deleteData: PropTypes.func.isRequired
  }

  render() {
    const {
      close,
      data,
      editDataName,
      tableData,
      availableData,
      type,
      saveDataName,
      handleDataNameChange,
      resetEditDataName,
      addData,
      removeData,
      deleteData
    } = this.props

    return (
      <div className={`data-panel ${type}`}>
        <div className='header row spc-btwn'>
          <Header content={data.name || "Data Details"} />
          <SvgIcon name='close' color='gray' onClick={close} />
        </div>
        <Form onSubmit={saveDataName}>
          <Form.Input
            label={<Header as="h4" content="Edit Name" />}
            value={editDataName}
            onChange={handleDataNameChange}
          />
          { editDataName !== data.name && (
            <div className="row spc-btwn">
              <Form.Button
                content="Cancel"
                onClick={resetEditDataName}
              />
              <Form.Button
                primary
                content="Save"
                type="submit"
              />
            </div>
          )}
        </Form>
        <AssignmentTable
          type="users"
          data={tableData}
          addData={addData}
          removeData={removeData}
          availableData={availableData}
        />
        <Button
          negative
          className="delete-btn"
          content="Delete"
          onClick={deleteData}
        />
      </div>
    )
  }
}
