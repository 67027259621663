import React, { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { BrowserRouter } from 'react-router-dom';
import { key } from 'firebase-key';

import './App.css';
import { Toast, ToastContent } from './Toast/Toast';
import Login from './Login/Login';
import base, { fire } from '../config/firebase';
import { firebaseFetch } from '../helpers/firebase';
import { friendlyEmailRef } from '../helpers/format';
import Main from './Main/Main';
import { toast } from 'react-toastify';
import { isUser } from '../helpers/access';

class App extends Component {
  state = {
    loading: true,
    authed: false,
    profile: undefined
  }

  componentDidMount = () => {
    this.setAuthState()
    console.log(key())
  }

  setAuthState = () => {
    fire.auth().onAuthStateChanged(async (user) => {
      let authed = false
      let profile = undefined
      if ( user ) {
        profile = await firebaseFetch(`/users/${user.uid}`)
        
        if ( !profile ) {
          profile = await this.getProfile(user.email, user.uid)
          await this.addRefToObjects(profile, user.uid)
        }
        if ( profile ) authed = true
      }

      if ( profile && !authed ) toast.error(<ToastContent error message="Access denied" />)
      this.setState({ authed, loading: false, profile })
    })
  }

  getProfile = async (email = "", uid = "") => {
    let emailRef = friendlyEmailRef(email)

    let profile = await firebaseFetch(`/profileBank/${emailRef}`)
    if ( !profile ) return 
    profile.uid = uid

    await base.update(`/users/${uid}`, { data: profile })
    return profile
  }

  addRefToObjects = async (profile, uid) => {
    if ( !profile ) return 
    const userRef = `/users/${uid}`

    // Add userRef to projectRefs
    await Promise.all(Object.values(profile.projectRefs || {}).map(async (projectRef) => {
      return await base.update(`${projectRef}/userRefs`, { data: { [userRef]: uid }})
    }))
    
    // Add userRef to equipmentRefs
    await Promise.all(Object.values(profile.equipmentRefs || {}).map(async (equipmentRef) => {
      return await base.update(`${equipmentRef}/userRefs`, { data: { [userRef]: uid }})
    }))

    // Add userRef to taskTypeRefs
    await Promise.all(Object.values(profile.taskTypeRefs || {}).map(async (taskTypeRef) => {
      return await base.update(`${taskTypeRef}/userRefs`, { data: { [userRef]: uid }})
    }))
  }

  render() {
    const { loading, authed, profile } = this.state
    
    return (
      <Dimmer.Dimmable className="App">
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <BrowserRouter>
          { authed ? <Main profile={profile} /> : <Login /> }
        </BrowserRouter>
        <Toast />
      </Dimmer.Dimmable>
    );
  }
}

export default App;
