import React, { Component } from 'react'
import PropTypes from 'prop-types'

import base from '../../../config/firebase';
import AssignmentDropdown from './AssignmentDropdown';
import { getProjectOptions } from '../../../helpers/options';
import { getActiveUserID } from '../../../helpers/firebase';
import { isAdmin } from '../../../helpers/access';

export default class ProjectDropdown extends Component {
  static propTypes = {
    companyID: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    defaultValue: PropTypes.string
  }

  state = {
    projectOptions: []
  }

  componentDidMount = () => {
    const { companyID, multiple } = this.props

    this.setProjectOptions(companyID, multiple)
  }

  setProjectOptions = async ( companyID, multiple = false ) => {
    const userProjectIDRefs = `/users/${getActiveUserID()}/projectRefs`
    const accessCode = await base.fetch(`/users/${getActiveUserID()}/role`, { context: this })
    const projectIDs = isAdmin(accessCode) ? undefined : await base.fetch(userProjectIDRefs, { context: this })
    const projectOptions = await getProjectOptions(companyID, multiple, projectIDs)

    this.setState({ projectOptions })
  }

  render() {
    const { projectOptions } = this.state
    const { handleChange, disabled, multiple, name, defaultValue } = this.props

    return (
      <AssignmentDropdown
        name={name || "projectRefs"}
        type="projects"
        options={projectOptions}
        handleChange={handleChange}
        disabled={disabled}
        multiple={multiple}
        defaultValue={defaultValue}
      />
    )
  }
}
