import React, { Component } from 'react'
import PropTypes from 'prop-types'

import base from '../../../config/firebase';
import AssignmentDropdown from './AssignmentDropdown';
import { isAdmin } from '../../../helpers/access';
import { getEquipmentOptions } from '../../../helpers/options';
import { getActiveUserID } from '../../../helpers/firebase';

export default class EquipmentDropdown extends Component {
  static propTypes = {
    companyID: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    defaultValue: PropTypes.string
  }

  state = {
    equipmentOptions: []
  }

  componentDidMount = () => {
    const { companyID, multiple } = this.props

    this.setEquipmentOptions(companyID, multiple)
  }

  setEquipmentOptions = async ( companyID, multiple = false ) => {
    const userEquipmentIDRefs = `/users/${getActiveUserID()}/equipmentRefs`
    const accessCode = await base.fetch(`/users/${getActiveUserID()}/role`, { context: this })
    const equipmentIDs = isAdmin(accessCode) ? undefined : await base.fetch(userEquipmentIDRefs, { context: this })
    const equipmentOptions = await getEquipmentOptions(companyID, multiple, equipmentIDs)

    this.setState({ equipmentOptions })
  }

  render() {
    const { equipmentOptions } = this.state
    const { handleChange, disabled, multiple, name, defaultValue } = this.props

    return (
      <AssignmentDropdown
        name={name || "equipmentRefs"}
        type="equipment"
        options={equipmentOptions}
        handleChange={handleChange}
        disabled={disabled}
        multiple={multiple}
        defaultValue={defaultValue}
      />
    )
  }
}
