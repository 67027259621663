import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'

import './Main.css';
import SideBar from './SideBar/SideBar';
import AppHeaderBar from './AppHeaderBar/AppHeaderBar';
import { serviceNameBank } from '../../helpers/banks';
import TaskTypes from './TaskTypes/TaskTypes';
import Equipment from './Equipment/Equipment';
import Tasks from './Tasks/Tasks';
import Projects from './Projects/Projects';
import Management from './Management/Management';
import NewDataModal from './NewDataModal/NewDataModal';
import { getCompanyIDFromPath, getServiceFromPath } from '../../helpers/format';

class Main extends Component {
  static propTypes = {
    profile: PropTypes.object
  }

  state = {
    path: "",
    companyID: "",
    service: "task",
    newDataModal: {
      visible: false
    }
  }

  componentDidMount() {
    const { history } = this.props

    if ( history ) this.setStateFromRoute(history.location.pathname)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if ( nextProps.history ) {
      if (nextProps.history.location.pathname !== prevState.path) {
        return { path : nextProps.history.location.pathname };
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.path !== this.state.path) {
      this.setStateFromRoute(this.state.path)
    }
  }

  setStateFromRoute = (path = "") => {
    const companyID = getCompanyIDFromPath(path)
    const service = getServiceFromPath(path)

    this.setState({ companyID, service })
  }

  generateHomePath = () => {
    const { profile } = this.props

    return `/tasks/${profile.preferredCompany}`
  }

  generateServicePath = (service) => {
    const { path } = this.state
    const companyID = getCompanyIDFromPath(path)

    return `/${service}/${companyID}`
  }

  showNewDataModal = (e) => {
    if (e) e.preventDefault()

    this.setState({
      newDataModal: {
        visible: true
      }
    })
  }

  closeNewDataModal = (e) => {
    if (e) e.preventDefault()

    this.setState({
      newDataModal: {
        visible: false
      }
    })
  }

  renderTasks = () => {
    const { profile } = this.props
    const { companyID } = this.state

    if ( !companyID ) return <Fragment />
    return (
      <Tasks companyID={companyID} accessCode={profile.role} />
    )
  }

  render() {
    const { profile } = this.props
    const { companyID, service, newDataModal } = this.state

    return (
      <div className="main">
        <SideBar generateServicePath={this.generateServicePath} accessCode={profile.role} />
        <div className="body">
          <AppHeaderBar
            header={serviceNameBank[service]}
            userName={profile.name}
            showNewDataModal={this.showNewDataModal}
          />
          <Switch>
            <Route strict path='/tasks/:companyID' component={this.renderTasks} />
            <Route strict path='/projects/:companyID' component={Projects} />
            <Route strict path='/equipment/:companyID' component={Equipment} />
            <Route strict path='/taskTypes/:companyID' component={TaskTypes} />
            <Route strict path='/management/:companyID' component={Management} />
            <Redirect to={this.generateHomePath()} />
          </Switch>
        </div>
        { newDataModal.visible && (
          <NewDataModal
            companyID={companyID}
            service={service}
            close={this.closeNewDataModal}
          />
        )}
      </div>
    )
  }
}

export default withRouter(Main)