import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Header, Dimmer, Loader } from 'semantic-ui-react';
import base from '../../../config/firebase';
import { FAKE_LOAD_TIMER } from '../../../helpers/constants';
import { formatTaskCountDetail, formatUserCountDetail, attachRefToObjects } from '../../../helpers/format';
import NoDataCard from '../NoDataCard/NoDataCard';
import EquipmentPanel from '../DataPanel/EquipmentPanel';

export default class Equipment extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  }

  state = {
    companyID: undefined,
    equipment: undefined,
    equipmentData: {},
    loading: true
  }

  componentDidMount = () => {
    const { companyID } = this.props.match.params
    
    this.setEquipmentListener(companyID)
  }

  componentWillUnmount = async () => {
    if ( this.ref ) await base.removeBinding(this.ref)
  }

  setEquipmentListener = async (companyID) => {
    if ( !companyID ) return 
    const equipmentRef = `/companies/${companyID}/equipment`
    if ( this.ref ) await base.removeBinding(this.ref)
    this.ref = base.listenTo(equipmentRef, {
      context: this,
      then(equipmentData) {
        equipmentData = attachRefToObjects(equipmentData, equipmentRef)
        let { equipment } = this.state
        if ( equipment ) equipment = equipmentData[equipment.id]

        this.setEquipmentData(equipmentData, equipment)
      }
    })
  }

  setEquipmentData = async (equipmentData = {}) => {
    this.setState({ equipmentData })
    setTimeout(() => {
      this.setState({ loading: false })
    }, FAKE_LOAD_TIMER)
  }

  handleBoxClick = (e, equipmentID = "") => {
    e.preventDefault()

    this.showEquipmentPanel(equipmentID)
  }

  showEquipmentPanel = (equipmentID = "") => {
    this.setState({
      equipment: this.state.equipmentData[equipmentID]
    })
  }

  closeEquipmentPanel = () => {
    this.setState({ equipment: undefined })
  }

  render() {
    const { equipment, equipmentData, loading } = this.state
    const dataKeys = Object.keys(equipmentData || {})

    return (
      <div className="equipment section dimmable">
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <div className="grid">
          { dataKeys.length === 0 ? (
            <NoDataCard type="equipment" />
          ) : dataKeys.map(key => (
            <EquipmentBox key={key} equipment={equipmentData[key]} onClick={this.handleBoxClick} />
          ))}
        </div>
        { equipment && (
          <EquipmentPanel
            equipmentRef={equipment.ref}
            close={this.closeEquipmentPanel}
          />
        )}
      </div>
    )
  }
}

class EquipmentBox extends Component {
  static propTypes = {
    equipment: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
  }

  render() {
    const { equipment, onClick } = this.props
    const userCount = Object.keys(equipment.userRefs || {}).length
    const taskCount = Object.keys(equipment.taskRefs || {}).length

    return (
      <div className="equipment box" onClick={(e) => onClick(e, equipment.id)}>
        <Header as="h3" content={equipment.name} />
        <div className="details">
          <Header as="h5" content={formatTaskCountDetail(taskCount)} />
          <Header as="h5" content={formatUserCountDetail(userCount)} />
        </div>
      </div>
    )
  }
}
