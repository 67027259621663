import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Header, Button } from 'semantic-ui-react';
import Clock from 'react-live-clock';
import { toast } from 'react-toastify';

import ProjectDropdown from '../../AssignmentDropdown/ProjectDropdown';
import EquipmentDropdown from '../../AssignmentDropdown/EquipmentDropdown';
import TaskTypeDropdown from '../../AssignmentDropdown/TaskTypeDropdown';
import ElapsedTime from './ElapsedTime';
import base from '../../../../config/firebase';
import { ToastContent } from '../../../Toast/Toast';
import { getActiveUserID } from '../../../../helpers/firebase';

export default class TaskForm extends Component {
  static propTypes = {
    companyID: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
  }

  state = {
    task: {
      start: undefined,
      stop: undefined,
      projectRef: "",
      equipmentRef: "",
      taskTypeRef: ""
    }
  }

  handleChange = (e, data) => {
    e.preventDefault()
    let { task } = this.state
    task[data.name] = data.value

    this.setState({ task })
  }

  handleTimeClick = (e, data) => {
    e.preventDefault()
    let { task } = this.state
    if ( !task.start ) task.start = new Date().valueOf()
    else if ( task.start && !task.stop ) task.stop = new Date().valueOf()
    else if ( task.start && task.stop ) {
      task.start = undefined
      task.stop = undefined
    }

    this.setState({ task })
  }

  submitForm = async (e) => {
    e.preventDefault()
    const { companyID, close } = this.props
    let { task } = this.state
    task.userRef = `/users/${getActiveUserID()}`

    const tasksRef = `/companies/${companyID}/tasks`
    const taskRefObject = await base.push(tasksRef, { data: task }).catch(this.handleError)
    const taskID = taskRefObject.key
    const taskRef = `${tasksRef}/${taskID}`

    // Add task references
    if ( task.projectRef ) await base.update(`${task.projectRef}/taskRefs`, { data: { [taskID]: taskRef }}).catch(this.handleError)
    if ( task.equipmentRef ) await base.update(`${task.equipmentRef}/taskRefs`, { data: { [taskID]: taskRef }}).catch(this.handleError)
    if ( task.taskTypeRef ) await base.update(`${task.taskTypeRef}/taskRefs`, { data: { [taskID]: taskRef }}).catch(this.handleError)
    if ( task.userRef ) await base.update(`${task.userRef}/taskRefs`, { data: { [taskID]: taskRef }}).catch(this.handleError)

    toast.success(<ToastContent success message="Task created" />)
    close()
  }

  handleError = (error) => {
    console.error(error)
    toast.error(<ToastContent error />)
  }

  validForm = () => {
    const { task } = this.state
    let valid = true

    if ( !task.start || !task.stop ) valid = false
    else if ( !task.taskTypeRef ) valid = false

    return valid
  }

  render() {
    const { companyID } = this.props
    const { task } = this.state
    let timeButtonText = "Start"
    if ( task.start ) timeButtonText = "Stop"
    if ( task.stop ) timeButtonText = "Reset"

    return (
      <Form onSubmit={this.submitForm}>
        <div className="time-section">
          <div className="row spc-btwn">
            <div className="row">
              <div className="time">
                <Header as="h4" content="Start Time" />
                { task.start ? (
                  <Header
                    as="h2"
                    content={<Clock key="start" format="hh:mma" date={task.start} />}
                  />
                ) : (
                  <Header
                    as="h2"
                    content={<Clock key="current" format="hh:mma" ticking={true} interval={1000} />}
                  />
                )}
              </div>
              <ElapsedTime start={task.start} stop={task.stop} />
            </div>
            <div className="time">
              <Header as="h4" content="Stop Time" />
              { task.stop ? (
                <Header
                  as="h2"
                  content={<Clock key="stop" format="hh:mma" date={task.stop} />}
                />
              ) : (
                <Header
                  as="h2"
                  content={"--"}
                />
              )}
            </div>
          </div>
          <Button
            primary={timeButtonText !== "Reset"}
            content={timeButtonText}
            onClick={this.handleTimeClick}
          />
        </div>
        <Form.Field required>
          <label>Task Type</label>
          <TaskTypeDropdown
            name="taskTypeRef"
            companyID={companyID}
            handleChange={this.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Project</label>
          <ProjectDropdown
            name="projectRef"
            companyID={companyID}
            handleChange={this.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Equipment</label>
          <EquipmentDropdown
            name="equipmentRef"
            companyID={companyID}
            handleChange={this.handleChange}
          />
        </Form.Field>
        <Form.Button
          primary
          content="Submit"
          type="submit"
          disabled={!this.validForm()}
        />
      </Form>
    )
  }
}
