import React, { Component } from 'react'
import PropTypes from 'prop-types'
import base from '../../../config/firebase';
import { Header, Dimmer, Loader } from 'semantic-ui-react';
import moment from 'moment';

import { attachRefToObjects, formatElapsedTime, getTaskDetails, formatTime } from '../../../helpers/format';
import { FAKE_LOAD_TIMER } from '../../../helpers/constants';
import NoDataCard from '../NoDataCard/NoDataCard';
import TaskPanel from '../DataPanel/TaskPanel';
import { getActiveUserID } from '../../../helpers/firebase';
import { isMaster, isAdmin } from '../../../helpers/access';

export default class Tasks extends Component {
  static propTypes = {
    companyID: PropTypes.string.isRequired,
    accessCode: PropTypes.number.isRequired
  }

  state = {
    tasksData: {},
    task: undefined,
    loading: true
  }

  componentDidMount = () => {
    const { companyID } = this.props
    
    this.setTaskListener(companyID)
  }

  componentWillUnmount = async () => {
    if ( this.ref ) await base.removeBinding(this.ref)
  }

  setTaskListener = async (companyID) => {
    if ( !companyID ) return 
    const tasksRef = `/companies/${companyID}/tasks`

    if ( this.ref ) await base.removeBinding(this.ref)
    this.ref = base.listenTo(tasksRef, {
      context: this,
      then(tasksData) {
        tasksData = attachRefToObjects(tasksData, tasksRef)
        let { task } = this.state
        if ( task ) task = tasksData[task.id]
        
        this.setTasks(tasksData, task)
      }
    })
  }

  setTasks = async (tasksData = {}, task) => {
    tasksData = await this.attachTaskDetails(tasksData)
    tasksData = await this.removeOtherUserTasks(tasksData)
    this.setState({ tasksData, task })
    setTimeout(() => {
      this.setState({ loading: false })
    }, FAKE_LOAD_TIMER)
  }

  attachTaskDetails = async (tasks = {}) => {
    let tasksWithDetails = {}
    await Promise.all(Object.keys(tasks).map(async (key) => {
      tasksWithDetails[key] = await getTaskDetails(tasks[key])
    }))

    return tasksWithDetails
  }

  removeOtherUserTasks = async (tasks = {}) => {
    const { accessCode } = this.props
    const userTaskIDs = await base.fetch(`/users/${getActiveUserID()}/taskRefs`, { context: this })
    if ( isAdmin(accessCode) ) return tasks

    Object.keys(tasks).forEach(id => {
      if ( !userTaskIDs[id] ) delete tasks[id]
    })

    return tasks
  }

  handleBoxClick = (e, taskID = "") => {
    e.preventDefault()

    this.showTaskPanel(taskID)
  }

  showTaskPanel = (taskID = "") => {
    console.log(this.state.tasksData[taskID])
    this.setState({
      task: this.state.tasksData[taskID]
    })
  }

  closeTaskPanel = () => {
    this.setState({ task: undefined })
  }

  render() {
    const { tasksData, task, loading } = this.state
    const { companyID, accessCode } = this.props
    const dataKeys = Object.keys(tasksData || {})
    
    return (
      <div className="tasks section dimmable">
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <div className="grid">
          { dataKeys.length === 0 ? (
            <NoDataCard type="tasks" />
          ) : dataKeys.map(key => (
            <TaskBox key={key} task={tasksData[key]} onClick={this.handleBoxClick} />
          ))}
        </div>
        { task && (
          <TaskPanel
            taskRef={task.ref}
            close={this.closeTaskPanel}
            companyID={companyID}
            isAdmin={isAdmin(accessCode)}
          />
        )}
      </div>
    )
  }
}

class TaskBox extends Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
  }

  render() {
    const { task, onClick } = this.props

    return (
      <div
        className="task box"
        onClick={(e) => onClick(e, task.id)}
      >
        <Header as="h3" content={task.name || "Task"} />
        <div className="details">
          <Header as="h5" content={`Start: ${formatTime(task.start)}`} />
          <Header as="h5" content={`Stop: ${formatTime(task.stop)}`} />
          <Header as="h5" content={`Elapsed Time: ${formatElapsedTime(task.stop - task.start)}`} />
          { task.projectName && <Header as="h5" content={`Project: ${task.projectName}`} />}
          { task.equipmentName && <Header as="h5" content={`Equipment: ${task.equipmentName}`} />}
          { task.userName && <Header as="h5" content={`By ${task.userName}`} />}
        </div>
      </div>
    )
  }
}
