import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { validateEmail } from '../../../../helpers/validate';
import { Form } from 'semantic-ui-react';
import ProjectDropdown from '../../AssignmentDropdown/ProjectDropdown';
import { formatRefsToObject, friendlyEmailRef } from '../../../../helpers/format';
import EquipmentDropdown from '../../AssignmentDropdown/EquipmentDropdown';
import TaskTypeDropdown from '../../AssignmentDropdown/TaskTypeDropdown';
import { ACCESS_CODES, getAccessOptions } from '../../../../helpers/access';
import base from '../../../../config/firebase';
import { toast } from 'react-toastify';
import { ToastContent } from '../../../Toast/Toast';

export default class UserForm extends Component {
  static propTypes = {
    companyID: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
  }

  state = {
    user: {
      name: "",
      email: "",
      phoneNumber: "",
      role: ACCESS_CODES.USER,
      projectRefs: [],
      equipmentRefs: [],
      taskTypeRefs: []
    }
  }

  validForm = () => {
    const { user } = this.state
    const phoneNumber = parsePhoneNumberFromString(user.phoneNumber, "US") // TODO: Should get country from Domain
    let valid = true 

    if ( user.name === "" ) valid = false
    else if ( !validateEmail(user.email) ) valid = false
    else if ( !phoneNumber || !phoneNumber.isValid() ) valid = false

    return valid
  }

  handleChange = (e, data) => {
    e.preventDefault()
    let { user } = this.state
    user[data.name] = data.value

    if ( data.name === "projectRefs" ) user.projectRefs = formatRefsToObject(data.value)
    else if ( data.name === "equipmentRefs" ) user.equipmentRefs = formatRefsToObject(data.value)
    else if ( data.name === "taskTypeRefs" ) user.taskTypeRefs = formatRefsToObject(data.value)

    this.setState({ user })
  }

  submitForm = (e) => {
    e.preventDefault()
    let { user } = this.state
    const { companyID, close } = this.props
    user.preferredCompany = companyID
    user.companies = { [companyID]: true } // Add reference to current company
    
    base.update(`/profileBank`, { data: { [friendlyEmailRef(user.email)]: user }})
      .then(() => {
        toast.success(<ToastContent success message="User created" />)
        close()
      })
      .catch(err => {
        console.error(err)
        toast.error(<ToastContent error />)
      })
  }

  render() {
    const { companyID } = this.props
    const { role } = this.state.user

    return (
      <Form onSubmit={this.submitForm}>
        <Form.Input
          required
          label="Name"
          name="name"
          onChange={this.handleChange}
        />
        <Form.Input
          required
          label="E-mail"
          name="email"
          onChange={this.handleChange}
        />
        <Form.Input
          required
          label="Phone Number"
          name="phoneNumber"
          onChange={this.handleChange}
        />
        <Form.Dropdown
          required selection
          label="Access Role"
          name="role"
          value={role}
          onChange={this.handleChange}
          options={getAccessOptions()}
        />
        <Form.Field>
          <label>Project Assignment</label>
          <ProjectDropdown
            multiple
            companyID={companyID}
            handleChange={this.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Equipment Assignment</label>
          <EquipmentDropdown
            multiple
            companyID={companyID}
            handleChange={this.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>TaskType Assignment</label>
          <TaskTypeDropdown
            multiple
            companyID={companyID}
            handleChange={this.handleChange}
          />
        </Form.Field>
        <Form.Button
          primary
          content="Create"
          disabled={!this.validForm()}
          type="submit"
        />
      </Form>
    )
  }
}
