import base from "../config/firebase";

export async function getProjectOptions(companyID, multiple = false, projectIDs) {
  const projectsRef = `/companies/${companyID}/projects`
  const projects = await base.fetch(projectsRef, { context: this })
  let projectOptions = []

  Object.keys(projects).forEach((id) => {
    let project = projects[id]
    if ( projectIDs && !projectIDs[id] ) return 

    projectOptions.push({
      key: id,
      value: `${projectsRef}/${id}`,
      text: project.name
    })
  })

  projectOptions =  orderAlphabetically(projectOptions)
  if ( !multiple ) projectOptions.unshift({ key: 'none', value: undefined, text: "None" })
  return projectOptions
}

export async function getEquipmentOptions(companyID, multiple = false, equipmentIDs) {
  const equipmentRef = `/companies/${companyID}/equipment`
  const equipmentData = await base.fetch(equipmentRef, { context: this })
  let equipmentOptions = []

  Object.keys(equipmentData).forEach((id) => {
    let equipment = equipmentData[id]
    if ( equipmentIDs && !equipmentIDs[id]) return 

    equipmentOptions.push({
      key: id,
      value: `${equipmentRef}/${id}`,
      text: equipment.name
    })
  })

  equipmentOptions =  orderAlphabetically(equipmentOptions)
  if ( !multiple ) equipmentOptions.unshift({ key: 'none', value: undefined, text: "None" })
  return equipmentOptions
}

export async function getTaskTypeOptions(companyID, multiple = false, taskTypeIDs) {
  const taskTypesRef = `/companies/${companyID}/taskTypes`
  const taskTypes = await base.fetch(taskTypesRef, { context: this })
  let taskTypeOptions = []

  Object.keys(taskTypes).forEach((id) => {
    let taskType = taskTypes[id]
    if ( taskTypeIDs && !taskTypeIDs[id] ) return 
    
    taskTypeOptions.push({
      key: id,
      value: `${taskTypesRef}/${id}`,
      text: taskType.name
    })
  })

  taskTypeOptions =  orderAlphabetically(taskTypeOptions)
  if ( !multiple ) taskTypeOptions.unshift({ key: 'none', value: undefined, text: "None" })
  return taskTypeOptions
}

function orderAlphabetically(options = [{}]) {
  return options.sort(compareName)
}

function compareName(a, b) {
  if (a.text < b.text)
    return -1;
  if (a.text > b.text)
    return 1;
  return 0;
}