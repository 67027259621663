import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Header } from 'semantic-ui-react';

import { formatElapsedTime } from '../../../../helpers/format';

export default class ElapsedTime extends Component {
  static propTypes = {
    start: PropTypes.number,
    stop: PropTypes.number
  }

  state = {
    milliSecondsElapsed: undefined
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      if ( !this.props.stop && this.props.start ) this.tick(this.props.start)
      else if ( !this.props.start ) this.setState({ milliSecondsElapsed: undefined })
    }, 1000);
  }

  tick = (start = new Date().valueOf(), stop = new Date().valueOf()) => {
    const milliSecondsElapsed = stop - start || 0

    this.setState({ milliSecondsElapsed })
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { milliSecondsElapsed } = this.state
    
    return (
      <div className="time elapsed">
        <Header as="h4" content="Elapsed Time" />
        { milliSecondsElapsed ? (
          <Header
            as="h2"
            content={formatElapsedTime(milliSecondsElapsed)}
          />
        ) : (
          <Header
            as="h2"
            content={"--"}
          />
        )}
      </div>
    )
  }
}
