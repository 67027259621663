import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './AppHeaderBar.css'
import { Header, Icon, Input, Dropdown } from 'semantic-ui-react';
import { logout } from '../../../helpers/firebase';
import { serviceWithCreate } from '../../../helpers/banks';

export default class AppHeaderBar extends Component {
  static propTypes = {
    header: PropTypes.string,
    userName: PropTypes.string.isRequired,
    showNewDataModal: PropTypes.func.isRequired
  }

  handleLogout = (e) => {
    e.preventDefault()

    logout()
  }

  render() {
    const { header, userName, showNewDataModal } = this.props

    return (
      <div className="app-header-bar">
        <div className="left">
          <Header content={header} as="h2" />
        </div>
        <div className="right">
          { serviceWithCreate[header] && (
            <div className="add block" onClick={showNewDataModal}>
              <Icon
                name="add"
              />
            </div>
          )}
          {/* <div className="search block">
            <Input
              icon='search'
              placeholder='Search...'
              iconPosition="left"
            />
          </div> */}
          {/* <div className="alerts block">
            <Icon name="bell outline" />
          </div> */}
          <div className="user-dropdown block">
            <Dropdown
              text={userName}
              direction="left"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  content="Logout"
                  onClick={this.handleLogout}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    )
  }
}
