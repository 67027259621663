import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Header } from 'semantic-ui-react'

import './Toast.css';
import SvgIcon from '../SvgIcon/SvgIcon';

export const Toast = () => (
  <ToastContainer
    position="top-right"
    autoClose={4000}
    hideProgressBar={false}
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    draggable
    pauseOnHover
    closeButton={<CloseButton />}
  />
)

const CloseButton = ({ closeButton }) => (
  <SvgIcon
    name="close"
    color="white"
    onClick={closeButton}
  />
)

export const ToastContent = ({ message, success, error }) => {
  let header = "Notice"
  if ( success ) header = "Success"
  else if ( error ) {
    header = "Error"
    if ( !message ) message = "Request failed, try again later"
  }

  return (
    <div className="content">
      <Header as="h3" content={header} />
      <Header as="h4" className="msg" content={message} />
    </div>
  )
}