import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Header, Dropdown } from 'semantic-ui-react';

import './AssignmentTable.css'
import { capitalize } from '../../../../helpers/format';

export default class AssignmentTable extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    availableData: PropTypes.object.isRequired,
    addData: PropTypes.func.isRequired,
    removeData: PropTypes.func.isRequired
  }

  dataToOptions = (data = {}) => {
    return Object.keys(data).map(key => {
      return {
        key,
        value: key,
        text: data[key].name
      }
    })
  }

  render() {
    const { type, data, availableData, addData, removeData } = this.props
    const dataKeys = Object.keys(data || {})
    const availableDataOptions = this.dataToOptions(availableData)

    return (
      <div className={`assignment-table ${type}`}>
        <div className="header row spc-btwn">
          <Header as="h4" content={capitalize(type)} />
          <Dropdown
            selection search
            placeholder={`Add ${type}...`}
            icon=''
            onChange={addData}
            options={availableDataOptions}
          />
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content="Name" />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { dataKeys.length === 0 ? (
              <Table.Row>
                <Table.Cell content={`No ${type} available`} />
              </Table.Row>
            ) : dataKeys.map(key => (
              <Table.Row key={key}>
                <Table.Cell content={data[key].name} />
                <Table.Cell className="remove-cell" content="Remove" onClick={(e) => removeData(e, key)} />
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    )
  }
}
