import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify';
import { clone } from 'lodash';

import './DataPanel.css'
import base from '../../../config/firebase';
import { ToastContent } from '../../Toast/Toast';
import DataPanel from './DataPanel';
import { getLastIDFromUrl } from '../../../helpers/format';

export default class TaskTypePanel extends Component {
  static propTypes = {
    taskTypeRef: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
  }

  state = {
    taskTypeRef: "",
    taskType: {},
    editName: "",
    userData: {},
    availableUsers: {}
  }

  componentDidMount = () => {
    const { taskTypeRef } = this.props

    this.setTaskTypeListener(taskTypeRef)
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if ( nextProps.taskTypeRef ) {
      if ( !prevState.taskType || nextProps.taskTypeRef !== prevState.taskTypeRef ) {
        return { taskTypeRef : nextProps.taskTypeRef };
      }
    }

    return null
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { taskTypeRef } = this.state

    if ( !prevState.taskType || prevState.taskTypeRef !== taskTypeRef ) {
      this.setTaskTypeListener(taskTypeRef)
    }
  }

  setTaskTypeListener = async (ref = "") => {
    if ( this.ref ) await base.removeBinding(this.ref)
    this.ref = base.listenTo(ref, {
      context: this,
      then(taskType) {
        this.setState({ editName: taskType.name, taskType })
        this.setAvailableUsers(taskType.userRefs)
        this.setUserData(taskType.userRefs)
      }
    })
  }

  setAvailableUsers = async (userRefs = {}) => {
    let availableUsers = await base.fetch('/users', { context: this })
    Object.keys(userRefs).forEach(id => {
      delete availableUsers[id]
    })

    this.setState({ availableUsers })
  }

  setUserData = async (userRefs = {}) => {
    let userData = {}
    await Promise.all(Object.keys(userRefs).map(async (key) => {
      if ( typeof userRefs[key] !== "string" ) return 
      userData[key] = await base.fetch(userRefs[key], { context: this })
    }))

    this.setState({ userData })
  }

  handleNameChange = (e, data) => {
    e.preventDefault()
    this.setState({ editName: data.value })
  }

  resetEditName = (e) => {
    e.preventDefault()
    this.setState({ editName: this.state.taskType.name })
  }

  saveName = (e) => {
    e.preventDefault()
    const { editName } = this.state
    const { taskTypeRef } = this.props

    base.update(taskTypeRef, { data: { name: editName }})
      .catch(() => {
        toast.error(<ToastContent error message="Failed to update taskType" />)
      })
  }

  addUser = async (e, data) => {
    e.preventDefault()
    let { taskType } = this.state
    let userID = data.value
    let userRefs = clone(taskType.userRefs || {})
    if ( userRefs && userRefs[userID] ) return 

    userRefs[userID] = `/users/${userID}`
    await base.update(this.props.taskTypeRef, { data: { userRefs }})
      .then(async () => {
        const taskTypeID = getLastIDFromUrl(this.props.taskTypeRef)
        await base.update(`/users/${userID}/taskTypeRefs`, { data: { [taskTypeID]: this.props.taskTypeRef }})
      })
      .catch(err => {
        console.error(err)
        toast.error(<ToastContent error message="Failed to add user" />)
      })
  }

  deleteTaskType = async (e) => {
    e.preventDefault()
    const { taskTypeRef, close } = this.props
    const taskTypeID = getLastIDFromUrl(taskTypeRef)
    let { taskType } = this.state

    // Remove taskType ref from users
    await Promise.all(Object.values(taskType.userRefs || {}).map(async (userRef) => {
      return await base.remove(`${userRef}/taskTypeRefs/${taskTypeID}`)
    })).catch(err => {
      console.error(err)
      return toast.error(<ToastContent error message="Failed to remove taskType" />)
    })

    // Remove taskType ref from tasks
    await Promise.all(Object.values(taskType.taskRefs || {}).map(async (taskRef) => {
      return await base.remove(`${taskRef}/taskTypeRef/${taskTypeID}`)
    })).catch(err => {
      console.error(err)
      return toast.error(<ToastContent error message="Failed to remove taskType" />)
    })

    await base.remove(taskTypeRef).catch(err => {
      console.error(err)
      return toast.error(<ToastContent error message="Failed to remove taskType" />)
    })

    toast.success(<ToastContent success message="TaskType deleted" />)
    close()
  }

  removeUser = async (e, userID) => {
    e.preventDefault()
    let { taskType } = this.state
    let userRefs = clone(taskType.userRefs)
    if ( !userRefs || !userRefs[userID] ) return 

    delete userRefs[userID]
    await base.update(this.props.taskTypeRef, { data: { userRefs }})
      .then(async () => {
        const taskTypeID = getLastIDFromUrl(this.props.taskTypeRef)
        await base.remove(`/users/${userID}/taskTypeRefs/${taskTypeID}`)
      })
      .catch(err => {
        console.error(err)
        toast.error(<ToastContent error message="Failed to remove user" />)
      })
  }

  render() {
    const { close } = this.props
    const { taskType, editName, userData, availableUsers } = this.state

    return (
      <DataPanel
        type={"taskType"}
        data={taskType}
        editDataName={editName}
        tableData={userData}
        availableData={availableUsers}
        close={close}
        saveDataName={this.saveName}
        handleDataNameChange={this.handleNameChange}
        resetEditDataName={this.resetEditName}
        addData={this.addUser}
        removeData={this.removeUser}
        deleteData={this.deleteTaskType}
      />
    )
  }
}
