import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import TaskForm from './TaskForm';
import ProjectForm from './ProjectForm';
import EquipmentForm from './EquipmentForm';
import TaskTypeForm from './TaskTypeForm';
import UserForm from './UserForm';

export default class Forms extends Component {
  static propTypes = {
    companyID: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
  }

  render() {
    const { companyID, service, close } = this.props
    
    switch(service) {
      case "tasks": return <TaskForm companyID={companyID} close={close} />
      case "projects": return <ProjectForm companyID={companyID} close={close} />
      case "equipment": return <EquipmentForm companyID={companyID} close={close} />
      case "taskTypes": return <TaskTypeForm companyID={companyID} close={close} />
      case "management": return <UserForm companyID={companyID} close={close} />
      default: return <Fragment />
    }
  }
}
