import React, { Component } from 'react'
import PropTypes from 'prop-types'
import base from '../../../config/firebase';
import { FAKE_LOAD_TIMER } from '../../../helpers/constants';
import { Dimmer, Loader, Header } from 'semantic-ui-react';
import { formatTaskCountDetail, formatUserAssignmentDetail, attachRefToObjects } from '../../../helpers/format';
import NoDataCard from '../NoDataCard/NoDataCard';
import UserPanel from '../DataPanel/UserPanel';

export default class Management extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  }

  state = {
    companyID: undefined,
    usersData: {},
    loading: true,
    user: undefined
  }

  componentDidMount = () => {
    const { companyID } = this.props.match.params
    
    this.setUserListener(companyID)
    this.setState({ companyID })
  }

  componentWillUnmount = async () => {
    if ( this.ref ) await base.removeBinding(this.ref)
  }

  setUserListener = async (companyID) => {
    if ( !companyID ) return 

    if ( this.ref ) await base.removeBinding(this.ref)
    this.ref = base.listenTo(`/users`, {
      context: this,
      then(usersData) {
        usersData = attachRefToObjects(usersData, `/users`)
        this.setUsers(usersData, companyID)
      }
    })
  }

  setUsers = async (usersData = {}, companyID) => {
    usersData = this.clearUsersOutsideOfCompany(usersData, companyID)
    this.setState({ usersData })
    setTimeout(() => {
      this.setState({ loading: false })
    }, FAKE_LOAD_TIMER)
  }

  clearUsersOutsideOfCompany = (usersData = {}, companyID) => {
    Object.keys(usersData).forEach(key => {
      if ( !usersData[key].companies[companyID] ) delete usersData[key]
    })

    return usersData
  }

  handleBoxClick = (e, userID = "") => {
    e.preventDefault()

    this.showUserPanel(userID)
  }

  showUserPanel = (userID = "") => {
    this.setState({
      user: this.state.usersData[userID]
    })
  }

  closeUserPanel = () => {
    this.setState({ user: undefined })
  }

  render() {
    const { usersData, loading, user, companyID } = this.state
    const dataKeys = Object.keys(usersData || {})

    return (
      <div className="users section dimmable">
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <div className="grid">
          { dataKeys.length === 0 ? (
            <NoDataCard type="users" />
          ) : dataKeys.map(key => (
            <UserBox key={key} user={usersData[key]} onClick={this.handleBoxClick} />
          ))}
        </div>
        { user && (
          <UserPanel
            companyID={companyID}
            userRef={user.ref}
            close={this.closeUserPanel}
          />
        )}
      </div>
    )
  }
}

class UserBox extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
  }

  render() {
    const { user, onClick } = this.props
    const projectCount = Object.keys(user.projectRefs || {}).length
    const equipmentCount = Object.keys(user.equipmentRefs || {}).length
    const taskTypeCount = Object.keys(user.taskTypeRefs || {}).length
    const taskCount = Object.keys(user.taskRefs || {}).length

    return (
      <div className="user box" onClick={(e) => onClick(e, user.id)}>
        <Header as="h3" content={user.name} />
        <div className="details">
          <Header as="h5" content={formatUserAssignmentDetail("project", projectCount)} />
          <Header as="h5" content={formatUserAssignmentDetail("equipment", equipmentCount)} />
          <Header as="h5" content={formatUserAssignmentDetail("taskType", taskTypeCount)} />
          <Header as="h5" content={formatTaskCountDetail(taskCount)} />
        </div>
      </div>
    )
  }
}
