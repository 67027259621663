import React, { Component } from 'react'
import { Header, Button } from 'semantic-ui-react';

import './Login.css';
import { signInWithGoogle } from '../../helpers/firebase';

export default class Login extends Component {
  handleLoginClick = (e) => {
    e.preventDefault()

    signInWithGoogle()
  }

  render() {
    return (
      <div className="login">
        <div className="box">
          <div className="logo">
            <span />
          </div>
          <Button
            primary
            content="Login with Google"
            onClick={this.handleLoginClick}
          />
          <Header as="h5">
            &copy; {new Date().getFullYear()} Autonomous Labs
          </Header>
        </div>
      </div>
    )
  }
}
