import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react';

import './AssignmentDropdown.css';

export default class AssignmentDropdown extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    defaultValue: PropTypes.string
  }

  render() {
    const { type, name, handleChange, disabled, options, multiple, defaultValue } = this.props

    return (
      <Dropdown
        selection search
        multiple={multiple}
        className={`assignment-dropdown ${type}`}
        placeholder="Select..."
        name={name}
        options={options}
        onChange={handleChange}
        disabled={disabled}
        defaultValue={defaultValue}
      />
    )
  }
}
