import React, { Component } from 'react'
import PropTypes from 'prop-types'

import base from '../../../config/firebase';
import AssignmentDropdown from './AssignmentDropdown';
import { getTaskTypeOptions } from '../../../helpers/options';
import { getActiveUserID } from '../../../helpers/firebase';
import { isAdmin } from '../../../helpers/access';

export default class TaskTypeDropdown extends Component {
  static propTypes = {
    companyID: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    defaultValue: PropTypes.string
  }

  state = {
    taskTypeOptions: []
  }

  componentDidMount = () => {
    const { companyID,  multiple } = this.props

    this.setTaskTypeOptions(companyID,  multiple)
  }

  setTaskTypeOptions = async ( companyID,  multiple = false ) => {
    const userTaskTypeIDRefs = `/users/${getActiveUserID()}/taskTypeRefs`
    const accessCode = await base.fetch(`/users/${getActiveUserID()}/role`, { context: this })
    const taskTypeIDs = isAdmin(accessCode) ? undefined : await base.fetch(userTaskTypeIDRefs, { context: this })
    const taskTypeOptions = await getTaskTypeOptions(companyID, multiple, taskTypeIDs)
    
    this.setState({ taskTypeOptions })
  }

  render() {
    const { taskTypeOptions } = this.state
    const { handleChange, disabled, multiple, name, defaultValue } = this.props

    return (
      <AssignmentDropdown
        name={name || "taskTypeRefs"}
        type="taskTypes"
        options={taskTypeOptions}
        handleChange={handleChange}
        disabled={disabled}
        multiple={multiple}
        defaultValue={defaultValue}
      />
    )
  }
}
